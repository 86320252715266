

/* light #e0e7ff */
/* mid #c7d2fe */
/* dark #6366f1 */


body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.placeholder-text-color::placeholder {
  color: #6366f1;
}

.field {
  margin-bottom: 10px;
}

.field label {
  display: block;
  font-size: 12px;
  color: #777;
}

.field input {
  display: block;
  min-width: 250px;
  line-height: 1.5;
  font-size: 14px;
}

input[type="submit"] {
  display: block;
  padding: 6px 30px;
  font-size: 14px;
  background-color: #6366f1;
  color: #fff;
  border: none
}

#form{
  display: grid;
  place-items: center;
  height: 5vh; /* Ensures the form is centered vertically within the viewport */
  margin: 0;
}

.socialMedia{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 22vh;
  text-align: center;
}

.text-center{
  margin: 2 px;
}


/* ConsentPopup.css */
.consent-popup {
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: #6366f1;
  color: #fff;
  padding: 15px;
  z-index: 1000;
}

.consent-popup-content {
  max-width: 800px;
  margin: 0 auto;
  text-align: center;
}

.consent-popup p {
  margin: 0 0 10px;
}

.consent-popup a {
  color: #4caf50;
  text-decoration: underline;
}

.consent-popup button {
  padding: 10px 20px;
  background-color: #c7d2fe;
  border: none;
  color: #6366f1;
  cursor: pointer;
}

.consent-popup button:hover {
  background-color: #e0e7ff;
}


/* PrivacyPolicyModal.css */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background-color: #fff;
  width: 90%;
  max-width: 800px;
  padding: 20px;
  border-radius: 5px;
  position: relative;
  overflow-y: auto;
  max-height: 80vh;
}

.modal-close {
  position: absolute;
  top: 15px;
  right: 15px;
  background: white;
  border: none;
  font-size: 24px;
  cursor: pointer;
}

.modal-body {
  margin-top: 40px; /* To avoid overlapping with close button */
}

.modal-body h2 {
  margin-top: 0;
}

.modal-body p {
  line-height: 1.6;
}

.popupButtons {
  display: inline;
  padding: 10px;
}